<template>
  <div class="app-container">
    <eHeader :query="query"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID" width="80px"/>
      <el-table-column label="站点名称">
        <template slot-scope="scope">
          <span>{{ getSiteName(scope.row.siteId)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="userId" label="用户ID" width="120px"/>
      <el-table-column prop="name" label="姓名/公司名称"/>
      <el-table-column prop="email" label="邮箱"/>
      <el-table-column prop="phone" label="电话"/>
      <el-table-column prop="content" label="内容" width="400px"/>
      <el-table-column prop="processingStatus" label="处理状态">
        <template slot-scope="scope">
          <span>{{ parseStatus(scope.row.processingStatus)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','SrCustLeavingMessage_ALL','SrCustLeavingMessage_EDIT'])" :data="scope.row" :sup_this="sup_this"/>
          <el-popover
            v-if="checkPermission(['ADMIN','SrCustLeavingMessage_ALL','SrCustLeavingMessage_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { del } from '@/api/leavingMessage/srCustLeavingMessage'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/leavingmessage/header'
  import edit from '@/components/leavingmessage/edit'
  export default {
    name:'leavingmessage',
    components: { eHeader, edit },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        sup_this: this,
        siteList: []
      }
    },
    created() {
      this.$nextTick(() => {
        this.init();
      })
    },
    methods: {
      parseTime,
      checkPermission,
      getSiteName(siteId){
        if(!siteId){
          return '-';
        }else {
          let siteName = '';
          for (let i = 0; i < this.siteList.length; i++) {
            if(this.siteList[i].id == siteId){
              siteName = this.siteList[i].siteName;
              break;
            }
          }
          return siteName || '-';
        }
      },
      parseStatus(status){
        switch(status){
          case 1: return '未处理'
          case 2: return '处理中'
          case 3: return '已处理'
          case 4: return '关闭'
        }
      },
      beforeInit() {
        this.url = '/crm/tCustLeavingMessage'
        const sort = 'id,desc'
        this.params = {
          page: this.page,
          size: this.size,
          sort: sort,
          phone: this.query.phone
        }
        return true
      },
      subDelete(id) {
        this.delLoading = true
        del(id).then(res => {
          this.delLoading = false
          this.$refs[id].doClose()
          this.init();
          this.$notify({
            title: '删除成功',
            type: 'success',
            duration: 2500
          })
        }).catch(err => {
          this.delLoading = false
          this.$refs[id].doClose()
          console.log(err.response.data.message)
        })
      },
    }
  }
</script>

<style scoped>

</style>
