<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        userId: this.data.userId,
        name: this.data.name,
        email: this.data.email,
        phone: this.data.phone,
        content: this.data.content,
        operatorId: this.data.operatorId,
        processingContent: this.data.processingContent,
        processingStatus: this.data.processingStatus,
        createTime: this.data.createTime,
        updateTime: this.data.updateTime
      }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
